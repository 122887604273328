<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <!-- Logged In User Profile Sidebar -->
      <!-- <user-profile-sidebar
        :shall-show-user-profile-sidebar="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        @close-sidebar="$emit('update:shall-show-user-profile-sidebar', false)"
      /> -->

      <!-- Sidebar Content -->
      <div class="sidebar-content" :class="{ show: mqShallShowLeftSidebar }">
        <!-- Sidebar close icon -->
        <span class="sidebar-close-icon">
          <feather-icon
            icon="XIcon"
            size="16"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </span>

        <!-- Header -->
        <div class="chat-fixed-search">
          <div class="d-flex align-items-center w-100">
            <!-- Search -->
            <!-- <b-input-group class="input-group-merge ml-1 w-100 round">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-prepend>
              <b-form-input v-model="searchQuery" placeholder="Search..." />
            </b-input-group> -->
          </div>
        </div>

        <!-- ScrollArea: Chat & Contacts -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          @ps-y-reach-end="infiniteHandler"
          class="chat-user-list-wrapper list-group scroll-area"
        >
          <!-- Chats -->
          <!-- <ul class="chat-users-list chat-list media-list">
            <chat-contact
              v-for="contact in filteredChatsContacts"
              :key="contact.id"
              :user="contact"
              tag="li"
              :class="{'active': activeChatContactId === contact.id}"
              is-chat-contact
              @click="$emit('open-chat', contact.id)"
            />
          </ul> -->
          <ul class="chat-users-list chat-list media-list">
            <b-link
              v-for="contact in filteredChatsContacts"
              :key="contact.userId"
              @click="$emit('close-chat-left-sidebar')"
              :to="{
                name: 'apps-chat-contact',
                params: { contactId: contact.userId },
              }"
            >
              <chat-contact :user="contact" tag="li" is-chat-contact />
            </b-link>
            <!-- <infinite-loading @infinite="infiniteHandler"></infinite-loading> -->
          </ul>
        </vue-perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BLink,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { ref, computed } from "@vue/composition-api";
import ChatContact from "./ChatContact.vue";
import UserProfileSidebar from "./UserProfileSidebar.vue";
import axios from "@axios";

export default {
  components: {
    // BSV
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BLink,

    // 3rd party
    VuePerfectScrollbar,

    // SFC
    ChatContact,
    UserProfileSidebar,
  },
  props: {
    profileUserData: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    // const resolveChatContact = (userId) =>
    //   props.contacts.find((contact) => contact.id === userId);

    // Search Query
    const searchQuery = ref("");

    // const searchFilterFunction = contact => contact.fullName.toLowerCase().includes(searchQuery.value.toLowerCase())
    // const filteredChatsContacts = computed(() => props.chatsContacts.filter(searchFilterFunction))
    // const filteredContacts = computed(() => props.contacts.filter(searchFilterFunction))

    const filteredChatsContacts = ref([]);
    const page = ref(0);
    const isLoading = ref(false);
    const infiniteHandler = () => {
      if (isLoading.value) {
        return;
      }

      isLoading.value = true;
      axios
        .get(`api/chat/userlastchat?page=${page.value}`)
        .then((response) => {
          if (response.data.length === 0) {
            return;
          }

          response.data.forEach((element) => {
            // 如果 filteredChatsContacts 包含 element.userId 就不要加入
            if (
              filteredChatsContacts.value.find(
                (contact) => contact.userId === element.userId
              )
            ) {
              return;
            }

            filteredChatsContacts.value.push(element);
          });

          page.value++;
          isLoading.value = false;
        })
        .catch((error) => reject(error));
    };

    infiniteHandler();

    return {
      // Search Query
      searchQuery,
      filteredChatsContacts,

      // UI
      // resolveChatContact,
      perfectScrollbarSettings,
      infiniteHandler,
    };
  },
};
</script>
