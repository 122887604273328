<template>
  <div
    class="user-profile-sidebar"
    :class="{ show: shallShowActiveChatContactSidebar }"
  >
    <!-- Header -->
    <header v-if="contact.name" class="user-profile-header">
      <span class="close-icon">
        <feather-icon
          icon="XIcon"
          @click="$emit('update:shall-show-active-chat-contact-sidebar', false)"
        />
      </span>

      <div class="header-profile-sidebar">
        <div class="avatar box-shadow-1 avatar-xl avatar-border">
          <b-avatar size="120" :src="contact.avatarUrl" />
        </div>
        <h4 class="chat-user-name">
          {{ contact.name }}
        </h4>
      </div>
    </header>

    <!-- User Details -->

    <vue-perfect-scrollbar
      v-if="contact.name"
      :settings="perfectScrollbarSettings"
      class="user-profile-sidebar-area scroll-area"
    >
      <h6 class="section-label mb-1 mt-3">基本資料</h6>
      <div class="mt-2">
        <h5 class="mb-75">生日</h5>
        <p class="card-text">{{ getDateDisplay(contact.birth) }}</p>
      </div>
      <div class="mt-2">
        <h5 class="mb-75">性別</h5>
        <p class="card-text">{{ contact.gender }}</p>
      </div>
      <div class="mt-2">
        <h5 class="mb-75">加入時間</h5>
        <p class="card-text">{{ getDateTimeDisplay(contact.joinTime) }}</p>
      </div>
      <div class="mt-2">
        <h5 class="mb-75">標籤</h5>
        <b-badge
          v-for="item in contact.tags"
          :key="item.key"
          class="mr-1"
          variant="primary"
        >
          {{ item.value }}
        </b-badge>
      </div>
      <div class="mt-2">
        <h5 class="mb-75">綁定狀態</h5>
        <p class="card-text">
          {{ contact.isLinked === 0 ? "未綁定" : "已綁定" }}
        </p>
      </div>
      <div class="mt-2">
        <h5 class="mb-75">綁定時間</h5>
        <p class="card-text">{{ getDateTimeDisplay(contact.linkedTime) }}</p>
      </div>

      <!-- Personal Info -->

      <h6 class="section-label mb-1 mt-3">聯絡資訊</h6>
      <ul class="list-unstyled">
        <li class="mb-1">
          <feather-icon icon="MailIcon" size="16" class="mr-75" />
          <span v-if="contact.email !== 'None'" class="align-middle">{{
            contact.email
          }}</span>
        </li>
        <li class="mb-1">
          <feather-icon icon="PhoneCallIcon" size="16" class="mr-75" />
          <span v-if="contact.phone !== 'None'" class="align-middle">{{
            contact.phone
          }}</span>
        </li>
        <li class="mb-1">
          <feather-icon icon="MapPinIcon" size="16" class="mr-75" />
          <span v-if="contact.phone !== 'None'" class="align-middle">{{
            contact.address
          }}</span>
        </li>
      </ul>

      <!-- FL Info -->
      <fl-order v-if="customId === 'cust_siro'" :contactId="contact.id" />      
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
} from "bootstrap-vue";
import { ref, computed } from "@vue/composition-api";
import { getDateTimeDisplay, getDateDisplay } from "@core/utils/utils";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import axios from "@axios";
import FlOrder from "./FlOrder.vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,

    // BSV
    BAvatar,
    BBadge,

    // 3rd Party
    VuePerfectScrollbar,

    AppCollapse,
    AppCollapseItem,
    FlOrder,
  },
  props: {
    shallShowActiveChatContactSidebar: {
      type: Boolean,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const customId = computed(() => {
      return JSON.parse(localStorage.getItem("userData")).customId;
    });
    
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    return {
      perfectScrollbarSettings,
      getDateTimeDisplay,
      getDateDisplay,
      customId,
    };
  },
};
</script>

<style scoped>
.avatar {
  cursor: default;
}

.card {
  box-shadow: none;
}
</style>
