<style scoped>
@media (max-width: 543px) {
  .chat-content audio {
    width: 240px;
  }
}

.chat-content {
  margin-bottom: 0 !important;
}

.chat-content p {
  word-break: break-word;
}

.chat-content img {
  width: 100%;
}

.chat-content-icon {
  display: flex;
}

.content-file-icon {
  display: flex;
  align-items: center;
}

.content-location-icon {
  display: flex;
  align-items: center;
}

.chat-content-media {
  padding: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.chat-time {
  float: right;
  clear: both;
  color: #b9b9c3;
  margin: 0 1rem 10px 0;
}

.chat-left .chat-time {
  float: left;
  margin: 0 0 10px 1rem;
}

.videoWrap >>> .video-js .vjs-big-play-button {
  border-width: 3px;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  line-height: 50px;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.chat-content-media-video {
  position: relative;
}

.chat-content-media-video .feather {
  position: absolute;
  color: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.media-container a {
  color: #fff;
}
</style>

<style>
.modal-body {
  padding: 0 !important;
}

.modal-content {
  text-align: center;
  background-color: transparent;
  box-shadow: none !important;
}
</style>
<template>
  <div class="chats">
    <div
      v-for="item in formattedChatData.formattedChatLog"
      :key="item.id"
      class="chat"
      :class="{ 'chat-left': item.isContact }"
    >
      <div class="chat-avatar">
        <b-avatar
          v-if="item.isContact"
          size="36"
          class="box-shadow-1"
          variant="transparent"
          :src="formattedChatData.contact.avatarUrl"
        />
        <b-avatar
          v-else
          size="36"
          class="box-shadow-1"
          variant="transparent"
          :src="userAvatarUrl"
        />
      </div>
      <div class="chat-body">
        <div v-for="message in item.messages" :key="message.id">
          <!-- 文字 -->
          <div v-if="message.messageType === 'text'" class="chat-content">
            <p>{{ message.messageText }}</p>
          </div>
          <!-- 圖片 -->
          <div
            v-if="message.messageType === 'image'"
            @click="showImageModal(message.contentUrl)"
            class="chat-content chat-content-media cursor-pointer"
          >
            <b-img :src="message.contentPreviewUrl" />
          </div>
          <!-- 影片 -->
          <div
            v-if="message.messageType === 'video'"
            @click="showVideoModal(message.contentUrl)"
            class="chat-content chat-content-media chat-content-media-video cursor-pointer"
          >
            <feather-icon icon="PlayCircleIcon" size="50" />
            <b-img :src="message.contentPreviewUrl" />
          </div>
          <!-- 音訊 -->
          <div
            v-if="message.messageType === 'audio'"
            class="chat-content chat-content-media"
          >
            <audio controls>
              <source :src="message.contentUrl" type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
          <!-- 檔案 -->
          <b-link
            v-if="message.messageType === 'file'"
            :href="`${baseUrl}File/LineFileContent/${message.id}`"
          >
            <div class="chat-content chat-content-icon">
              <div class="content-file-icon mr-1">
                <feather-icon icon="FileTextIcon" size="40" />
              </div>
              <div>
                <p>{{ message.originalFileName }}</p>
                <p>檔案大小：{{ message.fileSize }} MB</p>
              </div>
            </div>
          </b-link>
          <!-- 位置 -->
          <b-link
            v-if="message.messageType === 'location'"
            :href="`https://www.google.com/maps/search/?api=1&query=${message.latitude},${message.longitude}`"
            target="_blank"
          >
            <div class="chat-content chat-content-icon">
              <div class="content-location-icon mr-1">
                <feather-icon icon="MapPinIcon" size="20" />
              </div>
              <div>
                <p>{{ message.address }}</p>
              </div>
            </div>
          </b-link>
          <!-- 貼圖 -->
          <div
            v-if="message.messageType === 'sticker'"
            class="chat-content chat-content-media"
          >
            <b-img
              :src="`https://stickershop.line-scdn.net/stickershop/v1/sticker/${message.stickerId}/android/sticker.png`"
              width="200"
            />
          </div>
          <div class="chat-time">
            <small>{{ message.createTime }}</small>
          </div>
        </div>
      </div>
    </div>
    <!-- 影片 Modal -->
    <b-modal
      v-model="isShowVideoModal"
      hide-header
      hide-footer
      centered
      size="xl"
      no-close-on-backdrop
    >
      <div class="media-container">
        <b-link>
          <feather-icon icon="XCircleIcon" size="40" @click="hideVideoModal" />
        </b-link>
        <div class="videoWrap mt-2">
          <video-player
            :options="getVideoOptions"
            class="video-player vjs-custom-skin"
          />
        </div>
      </div>
    </b-modal>
    <!-- 圖片 Modal -->
    <b-modal
      v-model="isShowImageModal"
      hide-header
      hide-footer
      centered
      size="lg"
      no-close-on-backdrop
    >
      <div class="media-container">
        <b-link>
          <feather-icon icon="XCircleIcon" size="40" @click="hideImageModal" />
        </b-link>
        <div class="mt-2">
          <b-img :src="imageUrl" fluid />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { computed, ref } from "@vue/composition-api";
import {
  BAvatar,
  BImgLazy,
  BImg,
  BModal,
  VBModal,
  BButton,
  BLink,
} from "bootstrap-vue";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";

export default {
  components: {
    BAvatar,
    BImgLazy,
    BImg,
    BModal,
    BButton,
    BLink,
    videoPlayer,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const baseUrl = computed(() => {
      return process.env.VUE_APP_BASE_URL;
    });

    const videoUrl = ref(null); // modal 內的影片 url
    const imageUrl = ref(null); // modal 內的圖片 url
    const isShowVideoModal = ref(false);
    // 彈出影片 modal
    const showVideoModal = (url) => {
      videoUrl.value = url;
      isShowVideoModal.value = true;
    };
    
    // 關閉影片 modal
    const hideVideoModal = () => {
      isShowVideoModal.value = false;
    };

    const isShowImageModal = ref(false);
    // 彈出圖片 modal
    const showImageModal = (url) => {
      imageUrl.value = url;
      isShowImageModal.value = true;
    };

    // 關閉圖片 modal
    const hideImageModal = () => {
      isShowImageModal.value = false;
    };

    const getVideoOptions = computed(() => {
      return {
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        autoplay: true,
        muted: false,
        loop: false,
        preload: "auto",
        language: "zh-TW",
        aspectRatio: "15:6",
        fluid: true,
        sources: [
          {
            src: videoUrl.value,
            type: "video/mp4",
          },
        ],
        notSupportedMessage: "影片無法播放",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: true,
          progressControl: true,
          fullscreenToggle: true,
        },
      };
    });

    const getVideoPreviewOptions = (url) => {
      return {
        width: "300",
        height: "200",
        autoplay: false,
        muted: true,
        loop: false,
        language: "zh-TW",
        sources: [
          {
            src: url,
            type: "video/mp4",
          },
        ],
        notSupportedMessage: "影片無法播放",
      };
    };

    const userAvatarUrl = JSON.parse(localStorage.getItem("userData")).avatar;
    const formattedChatData = computed(() => {
      const contact = {
        name: props.chatData.contact.name,
        avatarUrl: props.chatData.contact.avatarUrl,
      };

      const chatLog = store.state.chat.activeChat.chatLog;
      const formattedChatLog = [];
      if (!chatLog.length) return formattedChatLog;
      let isContact = chatLog[0].isContact;
      let messageGroup = {
        isContact: chatLog[0].isContact ? chatLog[0].isContact : false,
        messages: [],
      };
      chatLog.forEach((element, index) => {
        const date = new Date(element.createTime);

        const formattedHours =
          (date.getHours() < 10 ? "0" : "") + date.getHours();
        const formattedMinutes =
          (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();

        const createTime = `${date.getFullYear()}/${
          date.getMonth() + 1
        }/${date.getDate()} ${formattedHours}:${formattedMinutes}`;
        if (element.isContact === isContact) {
          messageGroup.messages.push({
            id: element.id,
            messageText: element.messageText,
            createTime: createTime,
            messageType: element.messageType,
            contentUrl: element.contentUrl,
            contentPreviewUrl: element.contentPreviewUrl,
            originalFileName: element.originalFileName,
            fileSize: Math.round((element.fileSize / 1024) * 100) / 100,
            address: element.address,
            latitude: element.latitude,
            longitude: element.longitude,
            stickerId: element.stickerId,
          });
        } else {
          isContact = element.isContact;
          formattedChatLog.push(messageGroup);
          messageGroup = {
            isContact: element.isContact,
            messages: [
              {
                id: element.id,
                messageText: element.messageText,
                createTime: createTime,
                messageType: element.messageType,
                contentUrl: element.contentUrl,
                contentPreviewUrl: element.contentPreviewUrl,
                originalFileName: element.originalFileName,
                fileSize: Math.round((element.fileSize / 1024) * 100) / 100,
                address: element.address,
                latitude: element.latitude,
                longitude: element.longitude,
                stickerId: element.stickerId,
              },
            ],
          };
        }
        if (index === chatLog.length - 1) formattedChatLog.push(messageGroup);
      });

      return {
        formattedChatLog,
        contact,
      };
    });

    return {
      baseUrl,
      formattedChatData,
      userAvatarUrl,
      isShowVideoModal,
      showVideoModal,
      hideVideoModal,
      isShowImageModal,
      showImageModal,
      hideImageModal,
      getVideoOptions,
      getVideoPreviewOptions,
      videoUrl,
      imageUrl,
    };
  },
};
</script>
