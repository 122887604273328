<template>
  <div>
    <h6 class="section-label mb-1 mt-3">訂單資訊</h6>
    <app-collapse type="border">
      <app-collapse-item
        v-for="order in orders"
        :key="order.orderNumberDisplay"
        :title="order.orderNumberDisplay"
      >
        <b-row>
          <b-col cols="6">
            <h5>訂單金額</h5>
          </b-col>
          <b-col cols="6">
            <h5 class="float-right">$ {{ order.grandTotal }}</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <h5>訂單日期</h5>
          </b-col>
          <b-col cols="6">
            <h5 class="float-right">
              {{ getDateTimeDisplay(order.createDate) }}
            </h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <h5>訂單商品</h5>
          </b-col>
          <b-col cols="12">
            <h5
              v-for="product in order.orderProducts"
              :key="product.orderProductId"
              class="float-right"
            >
              1{{ product.productName }}
            </h5>
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import { ref, watch } from "@vue/composition-api";
import { BRow, BCol } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import axios from "@axios";
import { getDateTimeDisplay } from "@core/utils/utils";

export default {
  components: {
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
  },
  props: {
    contactId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const orders = ref([]);
    watch(
      () => props.contactId,
      (newVal) => {
        axios.get(`api/custom/FlOrder/${newVal}`).then((response) => {
          orders.value = response.data;
        });
      },
      {
        immediate: true,
      }
    );

    return { orders, getDateTimeDisplay };
  },
};
</script>
