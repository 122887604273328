<template>
  <component :is="tag" v-on="$listeners">
    <b-avatar
      size="42"
      :src="user.userAvatarUrl"
      class="badge-minimal"
      variant="info"
      :text="user.userName[0]"
    />
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">
        {{ user.userName }}
      </h5>
      <!-- 文字 -->
      <p v-if="user.messageType === 'text'" class="card-text text-truncate">
        {{ user.messageText }}
      </p>
      <!-- 圖片 -->
      <feather-icon
        v-else-if="user.messageType === 'image'"
        icon="ImageIcon"
        size="14"
        class="text-muted"
      />
      <!-- 影片 -->
      <feather-icon
        v-else-if="user.messageType === 'video'"
        icon="VideoIcon"
        size="14"
        class="text-muted"
      />
      <!-- 音訊 -->
      <feather-icon
        v-else-if="user.messageType === 'audio'"
        icon="Volume2Icon"
        size="14"
        class="text-muted"
      />
      <!-- 檔案 -->
      <feather-icon
        v-else-if="user.messageType === 'file'"
        icon="FileTextIcon"
        size="14"
        class="text-muted"
      />
      <!-- 位置 -->
      <feather-icon
        v-else-if="user.messageType === 'location'"
        icon="MapPinIcon"
        size="14"
        class="text-muted"
      />
      <!-- 貼圖 -->
      <feather-icon
        v-else-if="user.messageType === 'sticker'"
        icon="SmileIcon"
        size="14"
        class="text-muted"
      />
    </div>
    <div class="chat-meta text-nowrap">
      <small class="float-right mb-25 chat-time">{{
        formatDateToMonthShort(user.createTime, {
          hour: "numeric",
          minute: "numeric",
        })
      }}</small>

      <b-badge
        v-if="user.unReadCount"
        pill
        variant="primary"
        class="float-right"
      >
        {{ user.unReadCount }}
      </b-badge>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from "bootstrap-vue";
import { formatDateToMonthShort } from "@core/utils/filter";
import useChat from "./useChat";

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: "div",
    },
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveAvatarBadgeVariant } = useChat();
    return { formatDateToMonthShort, resolveAvatarBadgeVariant };
  },
};
</script>

<style></style>
