<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{
        show:
          shallShowUserProfileSidebar ||
          shallShowActiveChatContactSidebar ||
          mqShallShowLeftSidebar,
      }"
      @click="
        mqShallShowLeftSidebar =
          shallShowActiveChatContactSidebar =
          shallShowUserProfileSidebar =
            false
      "
    />

    <!-- Main Area -->
    <section class="chat-app-window">
      <!-- Start Chat Logo -->
      <div v-if="!activeChat.contact" class="start-chat-area">
        <div class="mb-1 start-chat-icon" @click="startConversation">
          <feather-icon icon="MessageSquareIcon" size="56" />
        </div>
        <h4 class="sidebar-toggle start-chat-text" @click="startConversation">
          開始聊天吧
        </h4>
      </div>

      <!-- Chat Content -->
      <div v-else class="active-chat">
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">
            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">
              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                size="36"
                :src="activeChat.contact.avatarUrl"
                class="mr-1 badge-minimal"
              />
              <h6 class="mb-0">
                {{ activeChat.contact.name }}
              </h6>
            </div>

            <!-- Contact Actions (Mobile only) -->
            <div class="d-flex align-items-center">
              <feather-icon
                icon="UserIcon"
                size="17"
                class="cursor-pointer d-lg-none mr-1"
                @click="shallShowActiveChatContactSidebar = true"
              />
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <div class="user-chats">
          <chat-log :chat-data="activeChat" />
        </div>

        <!-- Message Input -->
        <b-form class="chat-app-form" @submit.prevent="sendMessage">
          <a @click.prevent="$refs.refInputEl.$el.click()">
            <feather-icon class="mr-1" icon="ImageIcon" size="24" />
          </a>
          <b-form-file
            ref="refInputEl"
            accept=".jpg, .png"
            plain
            hidden
            @input="sendImageMessage"
          />
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="chatInputMessage"
              ref="refMessageInputEl"
              placeholder="輸入訊息"
              autofocus
            />
          </b-input-group>
          <b-button variant="primary" type="submit"> Send </b-button>
        </b-form>
      </div>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :active-chat-contact-id="
          activeChat.contact ? activeChat.contact.id : null
        "
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @close-chat-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>

    <portal to="content-renderer-sidebar-right">
      <chat-active-chat-content-details-sidedbar
        v-if="activeChat.contact"
        :shall-show-active-chat-contact-sidebar.sync="
          shallShowActiveChatContactSidebar
        "
        :contact="activeChat.contact"
      />
    </portal>
  </div>
</template>

<script>
import axios from "@axios";
import store from "@/store";
import {
  ref,
  onUnmounted,
  computed,
  nextTick,
  watch,
  onMounted,
} from "@vue/composition-api";
import {
  BAvatar,
  BDropdown,
  BDropdownItem,
  BForm,
  BInputGroup,
  BFormInput,
  BButton,
  BFormFile,
  BLink,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { $themeBreakpoints } from "@themeConfig";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import ChatLeftSidebar from "./ChatLeftSidebar.vue";
import ChatActiveChatContentDetailsSidedbar from "./ChatActiveChatContentDetailsSidedbar.vue";
import ChatLog from "./ChatLog.vue";
import useChat from "./useChat";
import { useRouter } from "@core/utils/utils";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { $isMobile } from "@/mixins/MobileCheck";

export default {
  components: {
    // BSV
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BFormFile,
    BLink,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  setup() {
    const { route, router } = useRouter();
    const contactId = route.value.params.contactId;

    onUnmounted(() => {
      // 清除 active chat
      store.commit("chat/UPDATE_ACTIVE_CHAT", {});
    });

    const CHAT_APP_STORE_MODULE_NAME = "app-chat";

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CHAT_APP_STORE_MODULE_NAME);
    });

    const { resolveAvatarBadgeVariant } = useChat();
    
    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------

    const fetchContactsAndLastChat = () => {
      store.dispatch("chat/fetchContactsAndLastChat", contactId);
    };

    fetchContactsAndLastChat();

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    // const activeChat = ref({});
    let activeChatData;
    const activeChat = computed(() => {
      return store.state.chat.activeChat;
    });

    const chatInputMessage = ref("");
    const openChatOfContact = (userId) => {
      // Reset send message input value
      chatInputMessage.value = "";
      axios
        .get(`api/chat/contact/${userId}`)
        .then((response) => {
          activeChatData = response.data;
          // Chat Message 存入 store
          store.commit("chat/UPDATE_ACTIVE_CHAT", activeChatData);

          // 未讀訊息設為 0
          store.dispatch("chat/setContactAllMessageRead", userId);
        })
        .catch((error) => reject(error));
    };

    if (contactId) {
      openChatOfContact(contactId);
    }

    const refMessageInputEl = ref(null);
    const routeParams = computed(() => route.value.params);
    watch(routeParams, () => {
      openChatOfContact(routeParams.value.contactId);
      if (!refMessageInputEl.value || $isMobile()) return;
      refMessageInputEl.value.$refs.input.focus();
    });

    // 發送訊息
    const sendMessage = () => {
      if (!chatInputMessage.value) return;
      const addChatMessage = {
        contactId: activeChatData.contact.id,
        message: {
          id: uuidv4(),
          messageText: chatInputMessage.value,
          isContact: false,
          createTime: Date(),
          messageType: "text",
        },
      };
      store.commit("chat/ADD_CHAT_MESSAGE", addChatMessage);

      axios
        .post(`api/chat/contact/${activeChat.value.contact.id}`, {
          messageText: chatInputMessage.value,
        })
        .then((response) => {});

      // Reset send message input value
      chatInputMessage.value = "";
    };

    const refInputEl = ref(null);

    // 發送圖片訊息
    const sendImageMessage = () => {
      const file = refInputEl.value.files[0];

      if (!file) {
        return;
      }

      const imageFormData = ref(new FormData());
      imageFormData.value.append("file", file);

      axios
        .post(
          `api/chat/Image/${activeChat.value.contact.id}`,
          imageFormData.value
        )
        .then((response) => {
          const addChatMessage = {
            contactId: activeChatData.contact.id,
            message: {
              id: uuidv4(),
              contentUrl: response.data.imageUrl,
              contentPreviewUrl: response.data.imagePreviewUrl,
              isContact: false,
              createTime: Date(),
              messageType: "image",
            },
          };
          store.commit("chat/ADD_CHAT_MESSAGE", addChatMessage);

          refInputEl.value.reset();
        });
    };

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({});

    const shallShowUserProfileSidebar = ref(false);
    const showUserProfileSidebar = () => {
      store.dispatch("app-chat/getProfileUser").then((response) => {
        profileUserData.value = response.data;
        shallShowUserProfileSidebar.value = true;
      });
    };

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false);

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true;
      }
    };

    if (!routeParams.value.contactId) startConversation();

    const uuidv4 = () => {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    };

    return {
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,

      // Single Chat
      // refChatLogPS,
      activeChat,
      chatInputMessage,
      openChatOfContact,
      sendMessage,
      sendImageMessage,
      refInputEl,
      refMessageInputEl,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,

      activeChat,
      uuidv4,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-chats {
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
